import { getCurrentInstance } from 'vue';
export function useToasted() {
    const instance = getCurrentInstance()?.proxy;
    function error(message) {
        instance?.$toasted.global.error({
            message,
        });
    }
    function success(message) {
        instance?.$toasted.global.success({
            message,
        });
    }
    return { error, success, controller: instance?.$toasted };
}
