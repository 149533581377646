export function useDownloadController() {
    async function downloadLargeFile(apiUrl, jwtToken) {
        const anchor = document.createElement('a');
        anchor.href = apiUrl;
        anchor.click();
    }
    return {
        downloadLargeFile,
    };
}
